/* eslint-disable react/jsx-no-target-blank */
import { ChevronDown, ChevronUp } from '@vgno/icons';
import { useEffect, useState } from 'react';
import './Snakkis.css';
import Story from './Story.jsx';
import Feedback from './Feedback.jsx';
import Preprompts from './Preprompts.jsx';
import {
    delay,
    getLastSeen,
    setLastSeen,
    setLastSeenInSessionStorage,
    setStoriesInSessionStorage,
    nextFrame,
    trackEngagement,
    shouldGetUpdate,
    getStories,
    getPropensityToUse,
    getPretendHoursSinceLastSeen,
    setStickySince,
    isSticky,
} from './utils.js';

import {
    trackImpression,
    trackLoad,
    trackShowMore,
    trackShowLess,
} from './tracking.js';
import { setLoginFlowState } from '@vgno/utils';

// Threshold for propensity to use the widget (0.7 = 30% highest propensity users)
const PROPENSITY_THRESHOLD = 0.90;

// Random chance of showing the widget for anonymous users (0.1 = 10% chance)
const RANDOM_CHANCE_FOR_ANONYMOUS_USERS = 0.50;

// The number of stories (bullet points) to fetch from the API
const NUMBER_OF_STORIES = 3;

/**
 * @typedef {Object} Message
 * @property {string} uuid - Unique identifier for the message.
 * @property {string} story_name - Name of the story.
 * @property {string} short_summary - A short summary of the story.
 * @property {string[]} bullet_point_summary - Bullet-point summary of the story.
 * @property {string} source - Source identifier for the story.
 */
const Snakkis = ({ node, getUnleashClient }) => {
    const [stories, setStories] = useState([]);
    const [summaries, setSummaries] = useState([]);
    const [user, setUser] = useState(null);
    const [opened, setOpened] = useState(false);
    const [startStreamingIndex, setStartStreamingIndex] = useState(-1);
    const [lastSeenState, setLastSeenState] = useState(0);
    const [showPreprompts, setShowPreprompts] = useState(false);

    useEffect(() => {
        async function start() {
            const identity = await getIdentity();
            const isLoggedIn = await identity.isLoggedIn();

            if (isLoggedIn) {
                const user = await identity.getUser();
                console.log('Skjedd Siden Sist: Initializing for user', user);
                initialize(user);
            } else {
                console.log(
                    'Skjedd Siden Sist: Initializing for anonymous user',
                );
                initialize();
            }
        }

        start();
    }, []);

    const initialize = async (user) => {
        const lastSeen = await getLastSeen(user);
        setLastSeenState(lastSeen);

        const pretendHoursSinceLastSeen = getPretendHoursSinceLastSeen();

        if (pretendHoursSinceLastSeen) {
            console.log(
                `Skjedd Siden Sist: 'debug_days' or 'skjeddsidensist' is set, pretending it's been ${pretendHoursSinceLastSeen} hours since last seen`,
            );
        } else {
            if (!shouldGetUpdate(lastSeen)) {
                console.log(
                    'Skjedd Siden Sist: Not enough time since last seen, aborting',
                );
                setLastSeen(user);
                return;
            }
        }

        setLastSeen(user);

        // For logged-in users, limit exposure by propensity to use
        if (user) {
            const propensity = await getPropensityToUse();

            if (
                !pretendHoursSinceLastSeen &&
                propensity < PROPENSITY_THRESHOLD
            ) {
                console.log(
                    'Skjedd Siden Sist: Propensity to use too low, aborting',
                );
                return;
            }
            // For anonymous users, limit exposure by random chance (but make sure to
            // show the widget if it's 'sticky', which means the user has attempted to log in
            // without completing the login flow, which is a strong signal of interest)
        } else {
            if (isSticky()) {
                console.log('Skjedd Siden Sist: Sticky is set');
            } else {
                if (Math.random() > RANDOM_CHANCE_FOR_ANONYMOUS_USERS) {
                    console.log(
                        'Skjedd Siden Sist: Random chance too low, aborting',
                    );
                    return;
                } else {
                    console.log(
                        'Skjedd Siden Sist: Random chance is high enough',
                    );
                }
            }
        }

        console.log('Skjedd Siden Sist: Fetching new stories');

        let stories = await getStories({
            topK: NUMBER_OF_STORIES,
            timestamp: lastSeen,
        });

        if (stories.length == 0) {
            console.log('Skjedd Siden Sist: No new stories, aborting');
            return;
        }

        trackLoad(getCustomTrackingData());

        if (user) {
            setUser(user);

            if (
                userIsInControlGroup(user.userId) &&
                !pretendHoursSinceLastSeen
            ) {
                console.log('User is in control group, aborting');
                return;
            }
        }

        trackImpression(node, getCustomTrackingData());

        setStories(stories);
        showComponent();
    };

    const getCustomTrackingData = () => {
        return {
            stories: stories.map((story) => story.story_name),
            secondsSinceLastVisit: Math.floor(
                Date.now() / 1000 - lastSeenState,
            ),
        };
    };

    const showComponent = async () => {
        node.style.setProperty('max-height', '0px');
        node.classList.add('snakkis-open');
        await delay(250);
        await nextFrame();
        node.style.setProperty('max-height', '200px');
        node.style.setProperty('min-height', '200px');
        node.style.setProperty('overflow', 'hidden');
    };

    const toggleOpen = () => {
        node.style.setProperty('transition', 'max-height 0.35s ease-in-out');
        node.style.setProperty(
            'max-height',
            opened ? '200px' : node.scrollHeight + 'px',
        );
        node.classList.toggle('snakkis-expanded');
        node.addEventListener(
            'transitionend',
            () => {
                node.style.setProperty('max-height', 'none');
            },
            { once: true },
        );

        if (opened) {
            trackShowLess({
                story: stories[0],
                custom: getCustomTrackingData(),
            });
        } else {
            trackShowMore({
                story: stories[0],
                custom: getCustomTrackingData(),
            });
        }

        setOpened(!opened);
    };

    useEffect(() => {
        async function checkPreprompts() {
            const unleashClient = await getUnleashClient();
            const result = unleashClient.isEnabled('skjedd-siden-sist-heivg');
            setShowPreprompts(result);
        }
        checkPreprompts();
    }, []);

    const userIsInControlGroup = (userId) => {
        // Users whose 4th last digit being 1 are in the control group
        return Math.floor((userId / 1000) % 10) === 1;
    };

    return (
        <section className="custom-style extended snakkis">
            <div className="card">
                <h2 className="snakkis-title">
                    <span className="greeting">
                        {user ? (
                            <>
                                Hei,{' '}
                                <span className="username">
                                    {user.givenName}
                                </span>
                                !
                            </>
                        ) : (
                            <>Hei!</>
                        )}
                    </span>{' '}
                    Dette har skjedd siden sist du var innom:
                </h2>
                <ul className="story-list-items">
                    {stories.length === 0 && (
                        <li className="story-list-item">
                            <span className="summary-loading" />
                        </li>
                    )}
                    {stories.map((story, i) => (
                        <Story
                            key={story.uuid}
                            storyId={story.uuid}
                            lastSeen={lastSeenState}
                            startStreaming={startStreamingIndex === i - 1}
                            finishedStreamingCallback={(summary) => {
                                setSummaries((summaries) => [
                                    ...summaries,
                                    summary,
                                ]);
                                setStartStreamingIndex(i);
                            }}
                        />
                    ))}
                </ul>

                {showPreprompts ? (
                    <Preprompts />
                ) : (
                    <Feedback
                        summaries={summaries}
                        customTrackingData={getCustomTrackingData()}
                    />
                )}

                <div className="ai-disclaimer ai-disclaimer-text">
                    Journalistikken vår siden ditt forrige besøk er oppsummert
                    med kunstig intelligens, basert på innhold som alltid er
                    vurdert av VGs redaksjon.&nbsp;
                    <a
                        href="https://www.vg.no/informasjon/redaksjonelle-avgjorelser/i14nmw?utm_campaign=skjeddsidensist"
                        target="_blank"
                    >
                        Les mer om hvordan det fungerer her
                    </a>
                    , og gi oss gjerne tilbakemelding hvis du oppdager noe som
                    er feil!
                </div>
            </div>

            {user ? (
                <button
                    className="snakkis-show-more"
                    onClick={toggleOpen}
                    onPointerUp={() => null}
                >
                    {opened ? (
                        <>
                            Vis mindre
                            <ChevronUp />
                        </>
                    ) : (
                        <>
                            Vis mer
                            <ChevronDown />
                        </>
                    )}
                </button>
            ) : (
                <button
                    className="snakkis-login"
                    onClick={login}
                    onPointerUp={() => null}
                >
                    <>
                        Logg inn (gratis)
                        <ChevronDown />
                    </>
                </button>
            )}
        </section>
    );

    async function login() {
        // If the user clicks "log in", set the sticky state to remember that
        // the user tried to log in but didn't complete it (and use it to ensure
        // that the widget is shown on consequent visits, since they've shown
        // interest in logging in for the feature)
        setStickySince();

        // The login state (specifically "origin") is used to redirect the
        // user back to the same page after login
        const makeLoginState = () => {
            const origin = `https://www.vg.no/?skjeddsidensist=24`;

            setStoriesInSessionStorage(stories);
            setLastSeenInSessionStorage(lastSeenState);

            return setLoginFlowState({
                medium: 'latest-news-chatbot',
                returnUrl: origin,
            });
        };

        // The login hint is used to enable automatic login for returning users
        const getLoginHint = async () => {
            if (!window.SPiD_Identity) return null;

            try {
                const context = await window.SPiD_Identity.getUserContextData();
                return context?.identifier;
            } catch (error) {
                return null;
            }
        };

        const state = makeLoginState();
        const loginHint = await getLoginHint();

        window.SPiD_Identity.login({ state, loginHint });

        trackEngagement('Click', {
            contentId: 'login',
            id: 'login',
            custom: getCustomTrackingData(),
        });
    }

    async function getIdentity() {
        return new Promise((resolve) => {
            if (window.Identity) {
                resolve(window.Identity);
            } else {
                window.addEventListener('identity-initialized', () => {
                    resolve(window.Identity);
                });
            }
        });
    }
};

export default Snakkis;
